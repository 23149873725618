<template>
  <div class="news">
    <top-banner 
      title="News & Articles" 
      subtitle="Enhance and grow your career by applying to work with STS's experienced experts" 
      :topimage="image"
    />
    
    <navbar/>
    <br/>

    <events />
    <br/>
  </div>
</template>

<script>
import image from "./../assets/img/people.jpg"
import TopBanner from '../components/TopBanner.vue'
import Navbar from '../components/Navbar.vue'
import Events from '../components/Events.vue'

export default {
  name: 'News',
  components: {
    TopBanner,
    Navbar,
    Events, 
  },
  data() {
    return{
      image,
    }
  }
}
</script>
