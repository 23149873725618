<template>
    <div class="center">
        <h4 class="news-above-main-title">EXPLORE NEWS & INSIGHTS</h4>
        <h1 class="news-main-title medium-title">News and Articles</h1>
        <div class="center" v-if="news.length">
            <v-row>
                <v-col cols="12" sm="6" md="4" v-for="newscontent in news" :key="newscontent.id">
                    <router-link :to="'/news/' + newscontent.id" style="text-decoration:none;">
                        <v-card class="news-card">

                            <v-img
                                height="250"
                                :src="newscontent.src"
                            ></v-img>

                            <v-card-title class="news-title">{{ newscontent.title }}</v-card-title>

                            <v-card-text>
                                <p class="news-description" v-html="newscontent.description">
                                </p>

                                <div>{{ newscontent.posted_date }}</div>

                            </v-card-text>

                        </v-card>
                    </router-link>
                </v-col>
            </v-row>

            <v-pagination
                v-if="totalItems>pageLimit"
                v-model="page"
                @input="changePage"
                :length="Math.ceil(totalItems/pageLimit)"
                color="#77C74E"
                style="margin-top:20px;"
            ></v-pagination><br/>
        </div>

        <div class="center" v-else style="margin:20px auto; color:#454545;">
            No news and articles.
        </div>
    </div>
</template>

<script>
import Data from './../data'

export default {
    data() {
        return {
            page: 1,
            news: Data.news,
            pageLimit: 6,
            totalItems: Data.news.length
        }
    },
    created() {
        this.news = Data.news.slice((this.page-1)* this.pageLimit, this.page* this.pageLimit) 
    },
    methods: {  
        changePage(page){
            this.news = Data.news.slice((page-1)* this.pageLimit, page* this.pageLimit) 
        }
    },

}
</script>

<style scoped>
.news-main-title{
  color:#2D2C2C;
  margin-bottom:5px;
  text-align: center;
}
.news-above-main-title{
  color:#ABABAC;
  letter-spacing: 3px;
  text-align: center;
}
.news-card{
    margin:18px;
    box-shadow: none !important;
    transition: .2s ease;
}
.news-title{
    color:#2D2C2C;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    height:87px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.news-description{
    color:#2D2C2C;
    text-align: justify;
    text-justify: inter-word;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    height:85px;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.news-card:hover{
    -ms-transform: scale(1.01);
    -webkit-transform: scale(1.01);
    transform: scale(1.01);
    border: none;
    box-shadow: 1px 5px 10px 5px rgba(0, 0, 0, 0.1) !important;
}

/* --------- Responsive ---------- */

@media only screen and (max-width: 400px) {
    .news-card{
        margin:10px;
    }
}
@media only screen and (min-width: 400px) {
    .news-card{
        margin:12px;
    }
}
@media only screen and (min-width: 600px) {
    .news-card{
        margin:12px;
    }
}
@media only screen and (min-width: 768px) {
    .news-card{
        margin:14px;
    }
}
@media only screen and (min-width: 880px) {
    .news-card{
        margin:14px;
    }
}
@media only screen and (min-width: 992px) {
    .news-card{
        margin:16px;
    }
}
@media only screen and (min-width: 1200px) {
    .news-card{
        margin:16px;
    }
}
@media only screen and (min-width: 1300px) {
    .news-card{
        margin:18px;
    }
}
@media only screen and (min-width: 1400px) {
    .news-card{
        margin:18px;
    }
}
</style>